<template>
<!-- 生物科技 -->
  <div class="about">
    <div class="nuox-about-header">
      <img src="http://39.104.95.82:8087/img/header/4.jpg" >
    </div>
    <div class="module-content">
      <div class="nuox-breadcrumb">
        <span class="nuox-breadcrumb-item"><router-link tag="span" :to="$t('lang.headerMenus[0].pageSrc')">{{ $t("lang.headerMenus[0].name") }}</router-link></span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[2].name") }}</span>
        <span class="iconfont icon-dian"></span>
        <span class="nuox-breadcrumb-item">{{ $t("lang.headerMenus[2].childrens[1].name") }}</span>
      </div>
      <div class="module-line"></div>
        <div :class="$i18n.locale=='en'?'module-nav-en':'module-nav'">
          <ul class="menu-vertical-nav">
            <li v-for="(item,index) in menus" :key="index" @click="checknav(item)">
              <div :class="'title '+[item.id==checkId?'open':'']" ><span v-html="item.name"></span><span :class="[item.id==checkId?'el-icon-arrow-down':'el-icon-arrow-right']+ ' icon-down'"></span></div>
            </li>
          </ul>
        </div>
        <div class="nuox-module">
          <div class="nuox-content-title">
            <div class="title-name">
              {{ $t("lang.headerMenus[2].childrens[1].name") }}
              <div class="xian"></div>
            </div>
          </div>
          <div class="nuox-module-cooperate">
            <div v-if="$i18n.locale=='zh'" v-html="strZh"  class="article-content"></div>
            <div v-if="$i18n.locale=='en'" v-html="strEn"  class="article-content"></div>
          </div>
        </div>
      <div class="module-line"></div>
    </div>
  </div>
</template>
<script>
export default {
  computed:{
    menus:function(){
      return this.$t('lang.headerMenus[2].childrens');
    }
  },
  data:function() {
    return {
        checkId:22,
        checkChildrenId:0,
        strEn:'',
        strZh:''
    };
  },
  mounted(){
    this.init();
  },
  methods:{
    async init(){
      var that = this;
      const { data: res } = await this.$http.get(
        "webSite/five_biology"
      );
      if(res.code==0){
        var data = res.data.data;
        that.strEn= data.biologyContentEn;
        that.strZh= data.biologyContentZh;
      }
    },
    checknav(e){
      if(e.pageSrc){
          this.$router.push({ 
            path: e.pageSrc,
            query: {}
          })
      }
      this.checkId = e.id;
    },
    checknavchildren(item,item2){
      this.checkId=item.id;
      this.checkChildrenId=item2.id;
    }
  }
}
</script>
<style>
@import '../../assets/css/about.css';
</style>